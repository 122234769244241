import { EmojiIndex } from 'emoji-mart-vue-fast/src';
import data from 'emoji-mart-vue-fast/data/all.json';
import { isNil } from 'lodash';

import customEmojisApi from '../api/custom_emojis';

const initialState = {
  show: false,
  type: null,
  id: null,
  left: 0,
  top: 0,
  lastOpenDateTime: null,
  customEmojis: [],
  loaded: false,
  emojiIndex: new EmojiIndex(data),
};

const actions = {
  openEmojiPicker({ commit }, payload) {
    commit('setEmojiPicker', { show: true, lastOpenDateTime: new Date(), ...payload });
  },
  closeEmojiPicker({ commit }) {
    commit('setEmojiPicker', initialState);
  },
  getCustomEmojis({ commit, state }, payload) {
    return customEmojisApi.getCustomEmojis(payload)
      .then((response) => {
        const emojis = response.data.customEmojis.map(
          emoji => ({
            'name': emoji.name,
            'short_names': emoji.shortNames,
            'text': emoji.text,
            'emoticons': emoji.emoticons,
            'keywords': emoji.keywords,
            'imageUrl': emoji.imageUrl,
          }),
        );
        commit('setCustomEmojis', emojis);
      }).finally(() => { state.loaded = true; });
  },
};

const mutations = {
  setEmojiPicker(state, payload) {
    state.show = payload.show;
    state.type = payload.type;
    state.id = payload.id;
    state.left = payload.left;
    state.top = payload.top;
    state.lastOpenDateTime = payload.lastOpenDateTime;
  },
  setCustomEmojis(state, payload) {
    state.customEmojis = payload;
    state.emojiIndex = new EmojiIndex(data, { custom: payload });
  },
};

const getters = {
  getEmoji: (state) => (id, skin) => {
    // eslint-disable-next-line no-underscore-dangle
    const emoji = state.emojiIndex._emojis[id];
    if (!emoji) return null;

    // eslint-disable-next-line no-underscore-dangle
    const emojiSkins = !isNil(skin) && emoji._skins;
    if (emojiSkins) {
      const emojiSkin = emojiSkins.find(({ colons }) => {
        const [, skinColons] = colons.split('::');

        return skinColons.replace(/:/g, '') === skin;
      });

      if (emojiSkin) {
        return emojiSkin;
      } else if (emojiSkins[skin - 1]) {
        // Skin is 1-indexed, so subtract 1 to get the correct index
        return emojiSkins[skin - 1];
      }
    }

    return emoji;
  },
};

export default {
  state: { ...initialState },
  actions,
  mutations,
  getters,
};
