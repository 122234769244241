<template>
  <loading-overlay v-if="loading" />
  <div
    v-else
    class="flex flex-col flex-1 overflow-hidden"
  >
    <div class="fixed top-14 lg:static bg-slate-50 p-2">
      <secondary-bar
        v-if="hasLargeScreen"
        :section-name="selectedSectionTitle"
        :document-name="documentTitle"
        :section-id="parseInt(selectedSectionId)"
        :document-id="documentId"
        @change-mobile-modal="changeMobileModal"
      >
        <document-status />
        <nav-actions
          v-if="hasLargeScreen"
        />
      </secondary-bar>
    </div>
    <mobile-modal
      v-if="showModalMobile && !hasLargeScreen"
      :document-instructions="documentInstructions"
      :document-id="documentId"
      :video-url="videoUrl"
      @hide-mobile-modal="changeMobileModal"
    />
    <router-view
      :document-id="documentId"
    />
  </div>
</template>

<script>
import { camelize, camelizeKeys } from 'humps';

import LoadingOverlay from 'components/shared/loading-overlay.vue';
import SecondaryBar from '../components/shared/secondary-bar.vue';
import DocumentStatus from '../components/meeting/document-status.vue';
import MobileModal from '../components/meeting/mobile-modal.vue';
import NavActions from '../components/design-system/nav-actions.vue';

export default {
  components: {
    LoadingOverlay,
    SecondaryBar,
    DocumentStatus,
    MobileModal,
    NavActions,
  },
  props: {
    documentId: {
      type: Number,
      required: true,
    },
    documentTitle: {
      type: String,
      required: true,
    },
    documentInstructions: {
      type: Object,
      default: null,
    },
    videoUrl: {
      type: String,
      default: null,
    },
    teamId: {
      type: Number,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: {},
      showModalMobile: false,
    };
  },
  computed: {
    loading() {
      return !this.$store.state.document.document.title || !this.$store.state.emoji.loaded;
    },
    sections() {
      return this.$store.state.document.sections || {};
    },
    selectedSection() {
      return this.sections[this.selectedSectionId];
    },
    selectedSectionId() {
      return this.$route.params.selectedSectionId;
    },
    selectedAnnouncementId() {
      return this.$route.params.selectedAnnouncementId;
    },
    selectedSectionTitle() {
      return (this.selectedSection) ? this.selectedSection.title : '';
    },
    hasLargeScreen() {
      return this.$store.getters.hasLargeScreen;
    },
    unreadMessagesCount() {
      return this.$store.getters.totalUnreadCount;
    },
  },
  watch: {
    unreadMessagesCount(newCount, oldCount) {
      const pastTitle = oldCount ? document.title.split(')')[1] : document.title;
      if (newCount === 0) {
        document.title = pastTitle;
      } else {
        document.title = `(${newCount}) ${pastTitle}`;
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch('setDocumentId', this.documentId);
    this.$store.dispatch('setTeam', this.teamId);
    this.$store.dispatch('setUser', this.user);

    await this.$store.dispatch('getDocument');
    await this.$store.dispatch('getCustomEmojis', this.teamId);
    this.$store.dispatch('getUnreadMessages', this.documentId);
  },
  mounted() {
    this.$cable.subscribe({ channel: 'DocumentChannel', id: this.documentId });
  },
  methods: {
    changeMobileModal() {
      this.showModalMobile = !this.showModalMobile;
    },
    updateNotifications(object) {
      const payload = { type: object.type === 'announcement' ? 'Announcements' : 'Comments', object };
      this.$store.dispatch('updateNotifications', payload);
    },
    isUsernameInMessage(message) {
      return this.$store.getters.isUsernameInMessage(message);
    },
  },
  channels: {
    DocumentChannel: {
      received(data) {
        const object = camelizeKeys(data.object);
        const action = camelize(`${data.action}_${data.type}`);
        this.$showNotification(action, object);

        this.$store.dispatch(action, object);
        if (data.action === 'create' && (object.type === 'announcement' || object.type === 'comment')) {
          if (this.isUsernameInMessage(object.body)) {
            this.updateNotifications(object);
          }
        }
      },
    },
  },
};
</script>
